<nv-wizard-header
  *ngIf="appConfigService.config.showHeaderWithFixedNavigationBar"
  [enableChat]="enableChat"
  [logoHref]="landingPageUrl"
  nv-action-area="header"
>
</nv-wizard-header>

<section
  class="content"
  nv-action-area="content"
  [ngClass]="{ 'mb-0 mb-md-5': showFooter$ | async }"
  [class.progress-fixed-top]="appConfigService.config.showHeaderWithFixedNavigationBar"
>
  <nv-progress-navigation [fixed]="appConfigService.config.showHeaderWithFixedNavigationBar">
    <li
      nvProgressNavigationItem
      *ngFor="let item of navigationItems; let i = index"
      [title]="item.translationKey | translate"
      [link]="item.link"
      [exact]="item.exact"
      [index]="i + 1"
      [navigatable]="item.navigatable | async"
      [iconPath]="showIcon ? pathUrl + 'icon' + (i + 1) + '.svg' : null"
    ></li>
  </nv-progress-navigation>
  <h3 class="mb-2" data-cy="app-title">{{ 'APP.TITLE' | translate }}</h3>
  <div [@pageAnimations]="pageAnimationKey$ | async">
    <nv-loadingspinner #spinner [loading]="navigationLoader.loading$ | async"></nv-loadingspinner>
    <div
      [hidden]="(spinner.showSpinner$ | async) && (navigationLoader.loading$ | async)"
      [ngClass]="{ 'pb-0 pb-md-3': showFooter$ | async }"
    >
      <nv-user-login-teaser></nv-user-login-teaser>
      <nv-user-login-teaser-success> </nv-user-login-teaser-success>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>
  <button *ngIf="enableMocks" nv-button (click)="loadMockData()">Lade Mocks</button>

  <footer *ngIf="showFooter$ | async" class="pricing bg-white mt-4 mt-md-0 p-2 px-md-0">
    <nv-pricing-footer [amount]="currentPrice$ | async | currency: '€':'symbol':'1.2'">
      <small
        *ngIf="(currentPrice$ | async) !== null"
        amount-info-text
        innerHTML="
          {{
          'PRICING_FOOTER.LABEL.' + ((riskAssessmentComplete$ | async) ? 'FINAL_PRICE' : 'PROVISIONAL_PRICE')
            | translate: { paymentCycle: 'PRICING_FOOTER.PAYMENT_CYCLE.' + (paymentCycle$ | async) | translate }
        }}
          {{ 'PRICING_FOOTER.TARIFF.' + (selectedModuleName$ | async | uppercase) | translate }}:"
      >
      </small>
      <nv-info-modal price-info-text *ngIf="(currentPrice$ | async) !== null">
        <ng-container *ngIf="(riskAssessmentComplete$ | async) === false">
          <h3 [innerHTML]="'PRICING_FOOTER.INFO.PROVISIONAL_PRICE.TITLE' | translate"></h3>
          <p [innerHTML]="'PRICING_FOOTER.INFO.PROVISIONAL_PRICE.TEXT' | translate"></p>
        </ng-container>
        <ng-container>
          <h3 [innerHTML]="'PRICING_FOOTER.INFO.PRICE.TITLE' | translate"></h3>
          <p
            [innerHTML]="
              'PRICING_FOOTER.INFO.PRICE.TEXT'
                | translate
                  : {
                      paymentCycle: ('PRICING_FOOTER.PAYMENT_CYCLE.' + (paymentCycle$ | async) | translate),
                      tariff: ('PRICING_FOOTER.TARIFF.' + (selectedModuleName$ | async | uppercase) | translate),
                      grossPremium: (currentGrossPrice$ | async | currency: '€':'symbol':'1.2')
                    }
            "
          ></p>
        </ng-container>
      </nv-info-modal>
      <nv-session-storage-agreement *ngIf="appConfigService.config.enableReentry"></nv-session-storage-agreement>
      <nv-user-login-button
        *ngIf="appConfigService.config.showLoginButtonInFooter"
        class="d-block d-md-inline-block text-center ms-md-2 mt-1 mt-md-0"
      ></nv-user-login-button>
      <nv-user-logout-button
        class="d-block d-md-inline-block text-center ms-md-2 mt-1 mt-md-0"
        *ngIf="appConfigService.config.showLoginButtonInFooter"
        [showTitle]="false"
      ></nv-user-logout-button>
    </nv-pricing-footer>
  </footer>

  <div
    class="row mt-3 mt-md-0"
    *ngIf="appConfigService.config.showRating"
    [hidden]="(spinner.showSpinner$ | async) || (navigationLoader.loading$ | async) || (hideRating$ | async)"
  >
    <div class="col-12 text-center">
      <span class="rating-logos" [ngClass]="{ 'has-dynamic-footer': showFooter$ | async }">
        <img class="mt-2 mx-4 mx-md-2" [src]="logo1Url" alt="Product Rating Logo 1" />
        <img class="mt-2 mx-4 mx-md-2 ml-lg-5" [src]="logo2Url" alt="Product Rating Logo 2" />
        <img class="mt-2 mx-4 mx-md-2 ml-lg-5" [src]="logo3Url" alt="Product Rating Logo 3" />
      </span>
    </div>
  </div>
</section>

<nv-user-login-dialog #loginDialog (dismissLoginAfterExpiration)="redirectToApplication()"> </nv-user-login-dialog>

<nv-modal #logoutSuccessModal>
  <ng-template modalContent>
    <div class="row justify-content-center align-items-center">
      <div class="col-12 text-secondary text-center display-1">
        <i class="fal fa-check-circle"></i>
      </div>
      <div class="col-12 text-center mb-2">
        <p id="logoutSuccessMessage">{{ 'APP.LOGOUT_BUTTON.MODAL_TEXT' | translate }}</p>
      </div>
    </div>
  </ng-template>
</nv-modal>
